@media(max-width:768px) {}

@media(max-width:992px) {
    a.xmas {
        display: none!important;
    }
    a.xmas-mobile {
        background: #bf2520;
        color: #fff;
        font-size: 12px;
        text-align: center;
        display: block;
        padding: 10px 0;
    }
    .hide-on-small {
        display: none!important;
    }
    body {
        width: 100%;
        overflow-x: hidden;
        height: 100%;
    }
    .navbar {
        height: 65px;
        margin-bottom: 0;
        border: 0;
    }
    .navbar-fixed-bottom,
    .navbar-fixed-top {
        position: absolute;
        top: 0;
    }
    .navbar-brand img {
        width: 95px!important;
        height: 29px!important;
        padding: 0;
        margin-left: 15px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .header-search-mail,
    .navsearch {
        margin-top: 20px;
    }
    li.menu-item>ul>li>a {
        font-size: 1.5em;
        font-weight: 200;
        color: #fff;
        display: block;
        width: 100%;
        border: 0;
        padding: 10px 0;
        margin-bottom: 0;
    }
    li.menu-item>ul>li>a:hover,
    li.menu-item>ul>li>a:focus,
    li.menu-item>ul>li>a:active {
        background: rgba(15, 96, 197, 0.8);
    }
    .statement {
        font-size: 3.5em!important;
    }
    .ourteam1,
    .team-member-banner,
    .workwithus,
    .webdev1,
    .webapp_bg1,
    .edm1,
    .ecom1,
    .video1,
    .design1,
    .contact1,
    .team-member-banner,
    .strategy1,
    .partner1 {
        height: 50%;
        background-position: right top!important;
        margin-bottom: 50px!important;
        background-size: cover;
        background-attachment: scroll!important;
        width: 100%;
    }
    .banner {
        height: 50%;
    }
    .blog-intro {
        font-size: 3.5em;
        width: 100%;
        line-height: 42px;
    }
    h1 {
        font-size: 3.5em;
        margin-bottom: 15px;
    }
    h2 {
        font-size: 2.5em;
    }
    h3 {
        font-size: 2.5em;
        margin: 15px 0;
    }
    .small-work {
        width: 50%;
        display: block;
        height: 180px;
        float: left;
        background-size: cover!important;
        background-position: right;
    }
    .small-work span {
        display: none;
    }
    .the-posts {
        margin-top: 50px;
    }
    .nav-overlay {
        height: auto;
        position: absolute;
        top: 0;
        padding-bottom: 50px;
        padding-top: 80px;
    }
    .navbar-nav {
        margin: 0;
    }
    nav>div,
    nav>div>ul,
    nav>div>ul>li {
        height: auto!important;
    }
    li.col-md-2 {
        padding: 0;
    }
    .sign-up-footer {
        margin-top: 40px;
    }
    .phones {
        display: block;
        float: none!important;
    }
    .webdev2 .bx-wrapper {
        margin-top: 50px!important;
    }
    .webdev3 .we-make,
    .webdev3 .we-make div {
        height: auto;
    }
    .webdev3 .we-make div {
        height: auto;
    }
    .webdev3 .we-make h3 {
        position: relative;
        top: 0;
        transform: none;
    }
    .webdev3 .we-make img {
        position: relative;
    }
    .webdev4,
    .ecom3,
    .ecom8,
    .edm4,
    .video3,
    .design4,
    .contact3 {
        background-attachment: scroll;
        background-size: cover;
        background-position: bottom right;
        display: block;
        height: 60%;
        margin: 50px 0 0;
    }
    .client-logos img {
        max-width: 32%;
    }
    a.website-item:last-child,
    a.video-item:last-child,
    a.website-item:last-child {
        display: none;
    }
    .ecom-examples .bigtext {
        font-size: 5em;
    }
    #counter {
        font-size: 16em;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 600;
    }
    .ecom-examples .small-work {
        display: block;
        float: left;
        height: 20%;
        overflow: hidden;
        width: 33.33333%;
    }
    .ecom-examples .small-work img {
        height: 100%!important;
        width: auto!important;
    }
    .ecom4 .col-md-7 {
        min-height: 530px;
    }
    .ecom4 {
        margin-bottom: 50px!important;
    }
    .ecom-examples {
        padding-top: 50px!important;
        height: 100%;
    }
    .ecom7 {
        padding-top: 50px;
    }
    .cord {
        height: 53px;
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        margin: 5px 0;
    }
    .cord span {
        background: none;
        font-size: 1.6em;
        font-weight: 200;
        color: #0f60c5;
        width: none;
        height: none;
        max-height: none;
        top: 0;
        transform: none;
        padding-top: 0px;
    }
    .cord span li {
        margin-left: 15px;
    }
    .cord .neg-cord {
        display: none;
    }
    .cord .pos-cord {
        display: none;
    }
    .home1 {
        background: none;
    }
    .home32 .col-md-4 {
        height: 16.6666%;
    }
    .slide1,
    .slide2,
    .slide3,
    .slide4,
    .slide5 {
        background-size: cover;
    }
    .webdev3 .one,
    .webdev3 .two {
        width: 50%!important;
        position: relative;
        display: block;
        margin: 20px 0;
        float: left;
        max-height: 112px;
        overflow: hidden;
    }
    .webdev3 .three,
    .webdev3 .four {
        width: 50%!important;
        position: relative;
        display: block;
        margin: 20px 0 20px;
        float: left;
        max-height: 105px;
        overflow: hidden;
    }
    .webdev3 .five,
    .webdev3 .six {
        width: 50%!important;
        position: relative;
        display: block;
        margin: 0 0 20px;
        float: left;
        max-height: 105px;
        overflow: hidden;
    }
    .restrict-width {
        max-width: 100%;
    }
    .buttons {
        padding: 20px 0;
    }
    a.generic-btn,
    a.graphic-btn,
    a.video-btn,
    a.website-btn {
        border: 2px solid #0f60c5;
        display: block;
        margin: 0 2.5% 5px;
        padding: 10px 20px 8px;
        width: 95%;
    }
    .work a:last-child {
        display: none;
    }
    .design3 {
        max-width: 100%;
        overflow: hidden;
        height: auto;
    }
    .design3 h3 {
        margin-top: 0;
    }
    .edm-graph {
        width: 500px;
        left: 100%;
        margin-left: -500px;
        position: relative;
    }
    .magazine {
        position: relative;
        z-index: 1;
        width: 500px;
        height: auto;
        margin-left: -80px;
    }
    .design5 .we-make,
    .design5 .we-make div {
        height: auto;
    }
    .design5 .we-make h3 {
        position: relative;
        top: auto;
        transform: none;
    }
    .design5 .we-make img.one,
    .design5 .we-make img.four {
        margin-bottom: 100px;
        position: relative !important;
    }
    .start-btn {
        display: none!important;
    }
    section.home1,
    section.home2,
    section.home3,
    section.home4,
    section.home32 {
        height: 100vh!important;
        margin: 0!important;
    }
    .home .bx-wrapper {
        height: 100vh!important;
    }
    .instaitem {
        width: 33.3333%;
        height: 25%;
        display: block;
        float: left;
        background-size: cover!important;
        opacity: 0.9;
    }
    .statement {
        bottom: 20px!important;
        line-height: 42px;
    }
    .single .big-date {
        font-size: 2em;
        color: #fff;
        letter-spacing: 0px;
        font-weight: 600;
        line-height: 45px;
        display: block;
        text-align: center;
    }
    .single .post-title-large {
        font-size: 3.5em;
        color: #fff;
        letter-spacing: 0px;
        font-weight: 600;
        line-height: 42px;
        display: block;
        text-align: center;
        margin-top: 0px;
    }
    .banner-image {
        height: auto;
        min-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .home32 .col-md-4 span {
        position: relative;
        top: 28%;
        transform: translateY(-48%);
        font-size: 0.8em;
    }
    .home4 {
        display: none!important;
    }
}

@media(max-width:1200px) {
    #fancybox-close,
    #fancybox-right-ico,
    #fancybox-left-ico {
        display: block!important;
    }
}