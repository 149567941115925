
@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-hairline-webfont.eot');
    src: url('../fonts/montserrat-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-hairline-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-hairline-webfont.woff') format('woff'),
         url('../fonts/montserrat-hairline-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;

}



@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-ultralight-webfont.eot');
    src: url('../fonts/montserrat-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-ultralight-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-ultralight-webfont.woff') format('woff'),
         url('../fonts/montserrat-ultralight-webfont.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;

}




@font-face {
    font-family: 'montserrat';
    src: url('../fonts/../fonts/montserrat-light-webfont.eot');
    src: url('../fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-light-webfont.woff') format('woff'),
         url('../fonts/montserrat-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;

}







@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-regular-webfont.eot');
    src: url('../fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-regular-webfont.woff') format('woff'),
         url('../fonts/montserrat-regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;

}



@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-semibold-webfont.eot');
    src: url('../fonts/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-semibold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-semibold-webfont.woff') format('woff'),
         url('../fonts/montserrat-semibold-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;

}


@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-bold-webfont.eot');
    src: url('../fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-bold-webfont.woff') format('woff'),
         url('../fonts/montserrat-bold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'montserrat';
    src: url('../fonts/montserrat-extrabold-webfont.eot');
    src: url('../fonts/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/montserrat-extrabold-webfont.woff') format('woff'),
         url('../fonts/montserrat-extrabold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;

}











