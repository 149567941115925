@media (max-width: 1199px) {
    .res_top_padding {
        padding-top: 0!important;
    }
}

.vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.pad-top-bottom {
    padding-top: 30px;
    padding-bottom: 30px;
}

a.link-arrow {
    font-weight: 300;
    border-bottom: 2px solid #1b71ce;
    padding-bottom: 5px;
    margin-top: 15px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

a.link-arrow:after {
    content: '';
    font-family: FontAwesome;
    margin-left: 20px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

a.link-arrow:hover {
    color: #1b71ce;
    text-decoration: none;
}

a.link-arrow:hover:after {
    margin-left: 30px;
}


/* GRID HOVER EFFECTS */

.grid {
    position: relative;
    margin: 0 auto;
    padding: 100px 0;
    list-style: none;
    text-align: center;
}


/* Common style */

.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0;
    min-width: 320px;
    max-height: 400px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    @media (min-width: 768px) {
        margin: 0%;
        width: 33.3333%;
    }
}

.grid figure img {
    position: relative;
    display: block;
    min-height: 100%;
    max-width: 100%;
    opacity: 0.8;
}

.grid figure figcaption {
    padding: 2em;
    color: #fff;
    font-size: 1em;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/* Anchor will cover the whole item by default */


/* For some effects it will show as a button */

.grid figure figcaption>a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h2 {
    font-weight: 600;
    font-size: 2.8em;
    text-align: center;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 1.5em;
}

figure.effect-hoverit img {
    opacity: 1;
    transition: opacity 0.35s, transform 0.35s;
    transform: scale(1);
}

figure.effect-hoverit:hover img {
    opacity: 1;
    transform: scale(1.2);
}

figure.effect-hoverit figcaption::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(56, 56, 56, 0) 0%, rgba(56, 56, 56, 1) 100%);
    content: '';
    opacity: 0;
}

figure.effect-hoverit h2 {
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    opacity: 0;
    color: #fff;
    transition: transform 0.35s, opacity 0.35s;
    transform: translate3d(0, -40%, 0);
}

figure.effect-hoverit figcaption::before,
figure.effect-hoverit p {
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-hoverit p {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    opacity: 0;
    transform: translate3d(0, 10px, 0);
}

figure.effect-hoverit:hover h2 {
    color: #fff;
    opacity: 1;
    transform: translate3d(0, -40%, 0) translate3d(0, -40px, 0);
}

figure.effect-hoverit:hover figcaption::before,
figure.effect-hoverit:hover p {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}


/* GRID HOVER EFFECTS */

p {
    margin: 10px 0 20px;
    font-weight: 200;
    font-size: 1.6rem;
    letter-spacing: 0.3px;
}

p strong {
    letter-spacing: 0px;
    font-weight: 500;
}

p strong a {
    letter-spacing: 0px;
    font-weight: 600!important;
}

.main_content {
    display: block;
    width: 100%;
    height: 100%;
    ul,
    li {
        font-weight: 200;
        font-size: 1.6rem;
        letter-spacing: 0.3px;
    }
}


/*======================================
  Selectric
======================================*/

.selectric-wrapper {
    position: relative;
    cursor: pointer;
}

.selectric-responsive {
    width: 100%;
}

.selectric {
    border: 2px solid #F0F0F0;
    background: #F8F8F8;
    position: relative;
    border-radius: 0;
}

.selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 30px 0 0;
    padding: 12px;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    color: #888;
    min-height: 18px;
}

.selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    height: 42px;
    color: #BBB;
    text-align: center;
    font: 0/0 a;
    /* IE Fix */
    *font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
}

.selectric .button:after {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #BBB;
    border-bottom: none;
}

.selectric-focus .selectric {
    border-color: #AAA;
}

.selectric-hover .selectric {
    border-color: #CCC;
}

.selectric-hover .selectric .button {
    color: #888;
}

.selectric-hover .selectric .button:after {
    border-top-color: #888;
}

.selectric-open {
    z-index: 9999;
}

.selectric-open .selectric {
    border-color: #CCC;
    background: #F0F0F0;
    border-bottom-width: 0;
    padding-bottom: 2px;
}

.selectric-open .selectric-items {
    display: block;
}

.selectric-open.selectric-above .selectric {
    border-top-width: 0;
    border-bottom-width: 2px;
}

.selectric-open.selectric-above .selectric-items {
    border-bottom-width: 0;
    border-top-width: 2px;
}

.selectric-disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectric-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
}

.selectric-hide-select select {
    position: absolute;
    left: -100%;
}

.selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    border: none;
    z-index: 1;
    box-sizing: border-box;
    opacity: 0;
}

.selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important;
}

.selectric-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
}


/* Items box */

.selectric-items {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #F0F0F0;
    border: 2px solid #CCC;
    border-top-width: 0;
    z-index: -1;
    padding: 0;
}

.selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto;
}

.selectric-above .selectric-items {
    top: auto;
    bottom: 100%;
}

.selectric-items ul,
.selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
}

.selectric-items li {
    display: block;
    padding: 10px 20px;
    color: #666;
    cursor: pointer;
}

.selectric-items li.highlighted {
    background: #D0D0D0;
    color: #444;
}

.selectric-items li.selected {
    background: #DDD;
    color: #444;
}

.selectric-items li:hover {
    background: #FEFEFE;
    color: #444;
}

.selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    color: #444;
}

.selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1;
}

.selectric-items .selectric-group li {
    padding-left: 25px;
}