.hamburger {
  padding: 7px 5px 3px;
  display: inline-block;
  outline: 0 !important;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #1b71ce;
  border: 0;
  margin: 0 0 0 10px;
  overflow: visible;
}

.hamburger--collapse.is-active.hamburger {
  padding: 10px 5px 0px;
}

.hamburger-box {
  width: 22px;
  height: 15px;
  display: inline-block;
  position: relative;
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
  width: 22px;
  height: 3px;
  background-color: #fff;
  border-radius: 0;
  position: absolute;
}

.hamburger--collapse .hamburger-box .hamburger-inner::before {
  top: -6px;
}
.hamburger--collapse .hamburger-box .hamburger-inner::after {
  top: -12px;
}
.hamburger--collapse.is-active .hamburger-box .hamburger-inner::before {
  top: 0;
}
