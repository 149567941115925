blockquote {
    padding: 40px;
    border: 0;
}

blockquote p {
    font-size: 1.8em;
    color: #333;
    width: 100%;
    font-weight: 600;
    letter-spacing: -1px;
    margin: 30px 0 50px 0;
    line-height: normal;
}

.entry-content li {
    font-size: 1.6em;
    font-weight: 200;
}

.page-header {
    margin-top: 0;
}

.banner {
    height: 60%;
    position: relative;
    overflow: hidden;
}

.blue-top {
    height: 60%;
    width: 100%;
    background: #0f60c5;
    position: relative;
    margin-bottom: 80px;
}

.banner-outer {
    height: 60%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: -1;
    background: #0f60c5;
}

.banner-image {
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.blog-intro {
    font-size: 42px;
    color: #fff;
    letter-spacing: -1px;
    font-weight: 700;
    line-height: 45px;
    position: absolute;
    bottom: 50px;
    width: 35%;
}

.sidebar-primary .main {
    float: right;
    width: 66.6667%;
}

.sidebar-primary h1,
.sidebar-primary h2 {
    text-align: left;
    margin-bottom: 15px;
}

.sidebar-primary h1 {
    font-size: 3.5em;
    margin-bottom: 30px
}

.sidebar-primary article {
    padding-bottom: 20px;
}

.sidebar-primary article img {
    width: auto;
    height: auto;
}

.the-tags {
    font-size: 1.3em;
}

.the-tags a {
    font-weight: 200;
}

.sidebar-primary time.updated {
    font-size: 1.3em;
    padding-bottom: 10px;
    font-weight: normal;
    margin: 0;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
}

.blog,
.tag {
    .blue-top {
        height: 40%;
    }
    time {
        padding-top: 20px!important;
        font-size: 1.2em;
    }
    article {
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 10px;
    }
    h2 {
        font-size: 2.6em;
        display: inline-block;
        cursor: pointer!important;
    }
    h2.entry-title a {
        cursor: pointer!important;
    }
    h2.entry-title a:hover {
        text-decoration: none;
        background: #0f60c5;
        color: #fff;
    }
    p {
        font-size: 1.5em;
    }
    .featured-work-thumb {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px;
        img {
            max-width: 100%;
        }
    }
    h2.tip {
        font-size: 3.6em;
        line-height: 40px;
        color: #0f60c5;
    }
}

.sidebar-primary p.byline a {
    font-weight: 100;
}

.single-support time.updated,
.single-post time.updated {
    font-size: 1em;
}

.single .entry-content {
    margin-top: 30px;
}

.single .entry-content {
    padding-right: 30px;
}

.single h2 {
    color: #333!important;
}

.single .big-date {
    font-size: 1.7em;
    color: #fff;
    letter-spacing: 0px;
    font-weight: 600;
    line-height: 45px;
    display: block;
    text-align: center;
}

.single .post-title-large {
    font-size: 4.5em;
    color: #fff;
    letter-spacing: 0px;
    font-weight: 600;
    line-height: 45px;
    display: block;
    text-align: center;
    margin-top: 20px;
}

aside ul li {
    font-weight: 200;
}

.post-type-archive-support #uwpqsf_id {
    border: 0!important;
    margin-bottom: 50px;
}

.post-type-archive-support input#uwpqsf_id_key {
    width: 100%;
    padding: 5px 50px 5px 10px;
    background: 0!important;
    box-shadow: none!important;
    margin: 0!important;
    border: 1px solid #ccc!important;
    border-radius: 0;
    font-size: 2.5em;
    font-weight: 100;
    color: #0f60c5!important;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    overflow: visible;
    height: auto;
    float: left;
}

.post-type-archive-support input#uwpqsf_id_key:focus {
    background: #0f60c5!important;
    border: 1px solid #0f60c5!important;
    color: #fff!important;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.post-type-archive-support ::-webkit-input-placeholder,
.post-type-archive-support :-moz-placeholder,
.post-type-archive-support ::-moz-placeholder,
.post-type-archive-support :-ms-input-placeholder {
    color: #111;
}

#uwpqsf_id_btn {
    position: absolute;
    right: 10px;
    width: auto;
    background: none!important;
    border: 0!important;
    padding: 10px 15px;
}

label.uwpqsf_class {
    display: none;
}

#uwpqsf_id_btn .fa-search {
    color: #333!important;
}

.posts-navigation {
    padding: 20px 0;
    font-size: 1.3em;
    clear: both;
}