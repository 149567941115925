html,
body {
  height: 100%;
  padding: 0;
  font-size: 10px;
}

body.page {
  overflow-x: hidden !important;
}

* {
  font-family: "montserrat", Arial, sans-serif;
}

img {
  image-rendering: auto;
  image-rendering: auto;
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
}

a {
  color: #1b71ce;
}

a:hover,
a:active,
a:focus {
  color: #333;
}

.container-fluid {
  padding: 0 5%;
}

.entry-content {
  margin-top: 0 !important;
}

.home1 {
  background-size: 917px 590px;
  height: 100%;
  display: block;
  overflow: hidden !important;
  z-index: 200;
  position: relative;
  margin-bottom: 10px !important;
}

.home2 {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: #ccc;
  display: block;
  overflow: hidden !important;
  z-index: 200;
  position: relative;
  margin-bottom: 0 !important;
}

.home2 .container-fluid {
  height: 100%;
  position: relative;
}

.home2 .text-box,
.home4 .text-box {
  color: #fff;
}

.home2 .text-box {
  color: #fff;
  text-shadow: none;
}

.home2 .down-arrow {
  margin-top: 30px;
}

//.home2{
// ul.bxslider{
//     padding: 5%;
//     margin: 0;
//     overflow: visible;
//   }
// ul.bxslider li {
//     padding: 0;
//     margin: 0;
// }
// .bx-wrapper{
//     background: none;
//     height: 90%;
// }
// .bx-wrapper *{
//     height: 100%!important;
// }
// ul.bxslider li img{
//  padding: 0;
//  float: right;
//   width: auto!important;
//   box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
//   margin-right: 12%;
//}
//}
ul.bxslider,
ul.bxslider li {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.home32 {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 300;
  overflow: hidden;
  margin-bottom: 50px;
  background: #fff;
}

.home32 .statement {
  position: absolute !important;
  top: 55%;
  margin-left: 5% !important;
  color: #fff !important;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
}

.home32 .col-md-4 {
  background-size: cover;
  height: 50%;
  text-align: center;
  padding: 0 !important;
}

.home32 .col-md-4 a {
  font-size: 3em !important;
  font-weight: 600 !important;
  background: rgba(255, 255, 255, 0.6);
  display: block;
  height: 100%;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.home32 .col-md-4 a:hover {
  background: none;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  color: #1b71ce !important;
}

.home32 .col-md-4 span {
  position: relative;
  top: 48%;
  transform: translateY(-48%);
}

.service-web-development {
  background: #fff url(../images/prinzi-mockup.jpg);
}

.service-video {
  background: #fff url(../images/filming.jpg);
}

.service-edm {
  background: #fff url(../images/edm-main.jpg);
}

.service-ecom {
  background: #fff url(../images/test2.png);
}

.service-design {
  background: #fff url(../images/inscon-branding.jpg);
}

.service-strategy {
  background: #fff url(../images/facebook-ecom.jpg);
}

.home3 {
  width: 100%;
  height: 100%;
  background: #fff url(../images/dog-bg.jpg) bottom center fixed no-repeat;
  background-size: 100% auto;
  display: block;
  z-index: 300;
  overflow: hidden;
  margin-bottom: 50px;
}

.home3 .statement {
  position: absolute !important;
  bottom: 80px;
  margin-left: 5% !important;
  color: #fff !important;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.8);
}

#instafeed {
  display: block;
  height: 100%;
}

.instaitem {
  width: 20%;
  height: 33.3333%;
  display: block;
  float: left;
  background-size: cover !important;
  opacity: 0.9;
}

.ri-shadow::after,
.ri-shadow::before {
  box-shadow: none !important;
  display: none !important;
  height: 0 !important;
}

.ri-grid {
  margin: 0 !important;
  height: 100% !important;
}

.ri-grid ul {
  height: 100% !important;
}

.ri-grid ul li a {
  background-size: cover;
  pointer-events: none;
  cursor: default;
  opacity: 0.9;
}

.ri-grid ul li {
  height: 33.3% !important;
  max-height: 33.3% !important;
  background: #fff;
}

.home4 {
  width: 100%;
  height: 100%;
  background: #1b71ce;
  background-size: 100% auto;
  display: block;
  z-index: 300;
  overflow: hidden;
  margin-bottom: 20% !important;
}

.home4 .statement {
  position: absolute !important;
  bottom: 80px;
  margin-left: 5% !important;
  color: #fff !important;
}

.home4 .home-posts {
  display: block;
  height: 50%;
  padding: 0 !important;
}

.home-posts {
  border-bottom: 1px solid #fff;
}

.home-posts:nth-child(2) {
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}

.home-posts a {
  font-size: 3em !important;
  font-weight: 600 !important;
  display: block;
  height: 100%;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  color: #fff !important;
  padding: 0 15%;
}

.home-posts a:hover {
  background: #fff;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  color: #1b71ce !important;
}

.home-posts a:hover .post-details {
  color: #1b71ce !important;
}

.home-posts .post-details {
  color: #fff !important;
  position: absolute;
  bottom: 30px;
}

.home-posts .post-details .post-date {
  font-size: 0.5em;
  font-weight: 400;
}

.home5 {
  width: 100%;
  min-height: 100%;
  background: #333 url(../images/contact-bg.png) center left fixed no-repeat;
  background-size: 100% auto;
  display: block;
  z-index: 300;
  overflow: hidden;
  color: #fff;
  position: absolute;
  padding-bottom: 60px;
}

.home5 strong {
  font-weight: 600;
  text-decoration: none;
}

.home5 a {
  color: #fff;
  text-decoration: underline;
}

.home5 .statement {
  color: #fff;
}

.navbar-brand > img.logo1 {
  float: left;
}

.navbar-brand > img.logo2 {
  display: none;
  float: left;
}

.text-box {
  font-weight: 100;
  letter-spacing: 0.5px;
}

.text-box strong {
  font-weight: 400;
  text-decoration: underline;
}

.a-post:nth-child(even) {
  clear: left;
}

.vertical-center-block {
  position: relative;
  top: 55%;
  transform: translateY(-55%);
}

.statement {
  font-size: 4.2em;
  color: #1b71ce;
  letter-spacing: -1.5px;
  font-weight: 700;
  line-height: 45px;
  z-index: 99999;
}

.statement-left {
  left: 5%;
}

.statement2 {
  font-size: 4.2em;
  color: #fff;
  letter-spacing: -1.5px;
  font-weight: 700;
  line-height: 45px;
  z-index: 99999;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.35);
}

.statement25 {
  font-size: 4.2em;
  color: #fff;
  letter-spacing: -1.5px;
  font-weight: 700;
  line-height: 45px;
  position: absolute;
  bottom: 55%;
  z-index: 99999;
}

.statement3 {
  font-size: 4.2em;
  color: #fff;
  letter-spacing: -1.5px;
  font-weight: 700;
  line-height: 45px;
  position: relative;
  z-index: 99999;
}

.addresses {
  margin-top: 150px;
}

.down-arrow {
  clear: both;
  margin-top: 30px;
}

.up-arrow {
  clear: both;
  margin-top: 50px;
}

.line {
  height: 3px;
  width: 50px;
  background: #333;
  margin-bottom: 30px;
  margin-top: 20px;
}

.home2 .line,
.home4 .line,
.home5 .line {
  background: #fff;
}

.home2 .statement {
  position: absolute !important;
  bottom: 80px;
  margin-left: 5% !important;
  color: #fff !important;
}

.navbar-header {
  height: 100%;
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  z-index: 999999;
}

nav {
  height: 50%;
}

nav > div,
nav > div > ul,
nav > div > ul > li {
  height: 100%;
}

.navbar {
  background: #fff;
  height: 80px;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  border-bottom: 1px solid #f6f6f6;
}

body.home .navbar {
  border-bottom: 0;
}

.navbar:hover {
  opacity: 1;
}

.navbar .container-fluid,
.nav-overlay .container-fluid {
  padding: 0 5%;
  width: 100%;
  height: 100% !important;
}

.navbar-brand {
  padding: 0;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.navbar-brand img {
  width: 121px;
  height: auto;
  padding: 0;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  transition: width 0.4s;
  margin: 0 15px;
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  image-rendering: optimizeQuality;
}

.navbar.shrink {
  height: 65px;
}

.navbar.shrink .navbar-brand img {
  width: 96px;
  height: auto;
  padding: 0;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  transition: width 0.4s;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  image-rendering: optimizeQuality;
}

.filters {
  display: block;
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

a.website-btn,
a.graphic-btn,
a.video-btn,
a.generic-btn {
  font-size: 1.6em;
  font-weight: 700;
  display: inline-block;
  padding: 10px 20px 8px;
  margin: 0 10px;
  cursor: pointer;
  border: 2px solid #1b71ce;
}

a.website-btn:hover,
a.graphic-btn:hover,
a.video-btn:hover,
a.generic-btn:hover {
  text-decoration: none;
}

a.website-btn.off,
a.graphic-btn.off,
a.video-btn.off {
  color: #ccc !important;
  text-decoration: line-through !important;
  border: 2px solid #ccc;
}

.website-item .small-work,
.graphic-item .small-work,
.video-item .small-work {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.website-item.off .small-work,
.graphic-item.off .small-work,
.video-item.off .small-work {
  width: 0px !important;
  overflow: hidden !important;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.buttons {
  padding: 100px 0;
  width: 100%;
  display: block;
  text-align: center;
  clear: both;
}

#atstbx {
  text-align: left !important;
}

.the-posts {
  margin-top: 100px;
}

.small-work {
  width: 33.333333%;
  display: block;
  height: 350px;
  float: left;
  background-size: 140% auto !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  transition: background-size 1s ease-out;
  -moz-transition: background-size 1s ease-out;
  -ms-transition: background-size 1s ease-out;
  -o-transition: background-size 1s ease-out;
  -webkit-transition: background-size 1s ease-out;
}

.small-work:hover {
  background-size: 180% auto !important;
  transition: background-size 8s ease-out;
  -moz-transition: background-size 8s ease-out;
  -ms-transition: background-size 8s ease-out;
  -o-transition: background-size 8s ease-out;
  -webkit-transition: background-size 8s ease-out;
}

.website-item.off .small-work:hover,
.graphic-item.off .small-work:hover,
.video-item.off .small-work:hover {
  background-size: 140% auto !important;
  cursor: none;
}

.small-work span {
  font-size: 2em;
  padding-top: 45%;
  color: #1b71ce;
  letter-spacing: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
  height: 100%;
  opacity: 0;
  text-align: center;
  font-weight: 100;
}

.small-work span strong {
  font-weight: 600;
  font-size: 1.5em;
}

.small-work span:hover {
  opacity: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  padding-top: 25%;
}

.website-item.off .small-work span:hover,
.graphic-item.off .small-work span:hover,
.video-item.off .small-work span:hover {
  opacity: 0;
  padding-top: 25%;
  cursor: not-allowed;
}

.ecom-examples {
  width: 100%;
  height: 120%;
  display: block;
  margin-top: 100px;
}

.many-built {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 100px;
  pointer-events: none;
}

.ecom-examples .bigtext {
  font-size: 8em;
  color: rgba(255, 255, 255, 1);
  width: 100%;
  text-align: left;
  font-weight: 900;
  letter-spacing: -1px;
  margin-top: 0px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.ecom-examples .small-work {
  width: 33.3333%;
  display: block;
  height: 33.3333%;
  float: left;
  overflow: hidden;
}

.ecom-examples .small-work img {
  width: 100%;
  height: auto;
}

#counter {
  font-size: 50em;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  width: 100%;
  text-align: center;
  display: block;
  margin: 0;
  line-height: 0.8em;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.cord {
  height: 53px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin: 5px 0;
}

.cord span {
  background: #1b71ce;
  font-size: 1.6em;
  font-weight: 200;
  color: #fff;
  display: block;
  width: 1000%;
  height: 30px;
  max-height: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-top: 3px;
}

.cord span li {
  margin-left: 40px;
}

.cord .neg-cord {
  position: absolute;
  left: 0;
  margin-left: -28px;
  border-left: 2px solid #fff;
}

.cord .pos-cord {
  position: absolute;
  left: 0;
  margin-left: -1339px;
}

.slide1 {
  background: url(../images/eureka.jpg) bottom center no-repeat;
  background-size: 100% auto;
  height: 100%;
}

.slide2 {
  background: url(../images/inscon-slide.jpg) top center no-repeat;
  background-size: 100% auto;
  height: 100%;
}

.slide3 {
  background: url(../images/prinzi-mockup.jpg) top center no-repeat;
  background-size: 100% auto;
  height: 100%;
}

.slide4 {
  background: url(../images/your-jeweller-ecom.jpg) top center no-repeat;
  background-size: 100% auto;
  height: 100%;
}

.slide5 {
  background: url(../images/cmpaphoneslide.jpg) top center no-repeat;
  background-size: 100% auto;
  height: 100%;
}

.webdev2 ul.bxslider,
.webdev2 ul.bxslider li {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center !important;
}

.webdev2 .bx-viewport {
  overflow-x: visible !important;
  overflow: visible !important;
}

.webdev2 .bx-wrapper img {
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.2);
}

.webdev2 .bx-wrapper {
  margin-top: 100px !important;
}

.webdev2 .bx-wrapper,
.webdev2 .bx-wrapper * {
  z-index: -100 !important;
}

.portfolio1 {
  display: block;
  height: 60%;
  overflow: hidden;
  z-index: -10;
}

.portfolio1 .slide1 {
  background: url(../images/cmpaphoneslide.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
}

.portfolio1 .slide2 {
  background: url(../images/pseslide.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
}

.portfolio1 .slide3 {
  background: url(../images/eurekaslide.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
}

.portfolio2 {
  z-index: 200;
  background: #fff;
  padding-top: 80px;
}

.teamsec2 {
  z-index: 200;
  background: #fff;
}

.portfolio .wrap {
  background: #fff;
}

.home .row,
.home .col-md-6,
.home .container-fluid {
  height: 100%;
}

.home .nav-overlay .row,
.home .nav-overlay .col-md-6,
.home .nav-overlay .container-fluid {
  height: auto;
}

::selection {
  background: #1b71ce;
  /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: #1b71ce;
  /* Gecko Browsers */
  color: #fff;
}

.box1 {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 60%;
}

.box2 {
  width: 150px;
  height: 150px;
  position: absolute;
  left: 80%;
}

.box3 {
  width: 300px;
  height: 300px;
  position: absolute;
  left: 55%;
}

.box4 {
  width: 250px;
  height: 250px;
  position: absolute;
  left: 90%;
}

.box5 {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
}

.single-team {
  padding-bottom: 100px;
}

.interview {
  padding: 100px 0;
  background: #1b71ce;
  margin-top: 100px;
}

.interview {
  color: #fff;
  font-size: 1.6em;
  font-weight: 100;
}

.interview strong {
  font-size: 1.2em;
  margin-bottom: 10px;
  display: inline-block;
}

.interview ul {
  padding: 0;
  margin: 0;
}

.interview li {
  list-style: none;
  padding: 0 15px;
  margin: 0 0 20px 0;
  display: inline-block;
  width: 24.5%;
  vertical-align: text-top;
}

.white,
.white a {
  color: #fff !important;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.triggermenu {
  margin-top: 40px;
  position: absolute;
}

.menu-btn-wrap {
  height: 100%;
  float: right;
}

.nav-buttons {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  right: 5%;
}

a.menu-btn,
div.menu-btn {
  color: #333;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  display: inline;
}

a.menu-btn span,
div.menu-btn span {
  font-size: 1.3em;
}

a.menu-btn .fa-navicon,
a.menu-btn .fa-times,
div.menu-btn .fa-navicon,
div.menu-btn .fa-times {
  background: #1b71ce;
  color: #fff;
  padding: 2px 3px;
  margin-left: 5px;
  font-size: 1.7em;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

a.menu-btn:hover .fa-navicon,
div.menu-btn:hover .fa-navicon {
  background: #333;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.fa-search {
  color: #fff;
  font-size: 1.8em;
  font-weight: 100;
}

a.menu-btn:hover,
div.menu-btn:hover {
  text-decoration: none;
}

a.start-btn {
  color: #1b71ce;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  margin-right: 35px;
  border: 2px solid #1b71ce;
  padding: 10px 10px 5px;
}

a.start-btn span {
  font-size: 1.3em;
}

a.start-btn .fa-plus {
  background: #1b71ce;
  color: #fff;
  padding: 2px 3px;
  margin-left: 5px;
  font-size: 1.7em;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

a.start-btn:hover .fa-plus {
  background: #333;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

a.start-btn:hover {
  text-decoration: none;
}

a.big-start-btn {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  border: 2px solid #fff;
  padding: 15px 15px 10px;
}

a.big-start-btn span {
  font-size: 1.6em;
}

a.big-start-btn:hover {
  text-decoration: none;
  opacity: 0.8;
}

.nav-overlay {
  background: rgba(15, 96, 197, 0.96) none repeat scroll 0 0;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999999;
  display: none;
  padding-top: 130px;
}

.navbar.nav-on {
  background: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  height: 80px;
  border-bottom: 0;
}

.nav-on a.menu-btn,
.nav-on div.menu-btn {
  color: #fff;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

footer.nav-on {
  color: #fff !important;
}

.header-search-mail {
  margin-top: 50px;
}

.navsearch .input-group,
.navsearch .input,
.form-control,
.header-search .input-group,
.header-search .input {
  width: 100%;
  border: 0 !important;
  background: 0 !important;
  box-shadow: none !important;
}

.nav-signup input {
  width: 100%;
  border: 0 !important;
  background: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0;
  padding: 0;
  font-size: 1.6em;
  font-weight: 100;
  color: #fff !important;
  -webkit-transition: padding 0.3s;
  -moz-transition: padding 0.3s;
  transition: padding 0.3s;
  overflow: visible;
  height: auto;
}

.nav-signup p {
  margin: 0 !important;
}

.nav-signup input:focus {
  margin-bottom: -20px;
  padding-bottom: 10px;
  padding-top: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.nav-signup input.join-submit {
  font-family: FontAwesome;
  position: absolute;
  right: 20px;
  width: auto;
  display: inline-block !important;
  white-space: nowrap;
  font-size: 1.4em;
  text-decoration: none;
  border-bottom: 0 !important;
  top: 0;
}

.nav-signup * {
  color: #fff !important;
}

.navsearch ::-webkit-input-placeholder,
.nav-signup ::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.8;
}

.navsearch ::-moz-placeholder,
.nav-signup ::-moz-placeholder {
  color: #fff;
  opacity: 0.8;
}
/* firefox 19+ */

.navsearch :-ms-input-placeholder,
.nav-signup :-ms-input-placeholder {
  color: #fff;
  opacity: 0.8;
}
/* ie */

.navsearch input:-moz-placeholder,
.nav-signup input:-moz-placeholder {
  color: #fff;
  opacity: 0.8;
}

.form-control {
  border-bottom: 1px solid #fff !important;
  border-radius: 0;
  padding: 0;
  font-size: 2.5em;
  font-weight: 100;
  color: #fff !important;
  -webkit-transition: padding 0.3s;
  -moz-transition: padding 0.3s;
  transition: padding 0.3s;
  overflow: visible;
  height: auto;
}

.form-control:focus {
  padding-bottom: 10px;
  padding-top: 10px;
  -webkit-transition: padding 0.3s;
  -moz-transition: padding 0.3s;
  transition: padding 0.3s;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #fff;
}

.input-group-btn {
  position: absolute;
  right: 0;
  width: auto;
}

.navsearch .search-submit {
  background: none;
  border: 0;
}

.header-search .search-submit {
  background: none;
  border: 0;
}

.nav-overlay nav,
.nav-overlay .menu-test-container,
.nav-overlay ul.navbar-nav {
  width: 100%;
}

ul#menu-main-menu > li {
  padding: 0 15px !important;
}

li.col-lg-2 a {
  padding: 10px 0;
}

li.menu-item > a {
  font-size: 1.7em;
  font-weight: 500;
  color: #fff;
  display: block;
  width: 100%;
  border-bottom: 1px solid #fff;
  margin-bottom: 15px;
  padding: 15px 0;
}

li.menu-item > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  width: 100%;
}

li.menu-item > ul > li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  width: 100%;
}

li.menu-item > ul > li > a {
  font-size: 1.5em;
  font-weight: 200;
  color: #fff;
  display: block;
  width: 100%;
  border: 0;
  padding: 5px 0;
  margin-bottom: 0;
}

li.menu-item > ul > li.menu-social-icons {
  display: inline-block;
  width: auto;
}

li.menu-item > ul > li.menu-social-icons a {
  padding: 5px 0;
  margin-right: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 2em !important;
}

li.menu-item > ul > li.menu-social-icons > a:hover {
  display: inline-block;
  width: auto;
  text-decoration: none !important;
  border-bottom: 3px solid #fff;
  padding: 5px 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.nav > li > a:focus,
.nav > li > a:hover {
  background: none;
}

.caret {
  display: none;
}

.social-icons {
  font-size: 2.5em;
  margin-top: 30px;
}

.social-icons a {
  margin-right: 10px;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #1b71ce;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  transition: color 0.3s;
}

.staff-details {
  margin: 40px 0;
}

.staff-details span {
  font-size: 1.6em;
  font-weight: 200;
  margin-right: 20px;
}

.icon-square-bg {
  width: 25px;
  height: 25px;
  display: inline-block;
  background: #333;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.icon-square-bg i.fa {
  color: #fff;
  margin-top: 5px;
  font-size: 1.4em;
}
/* Home contact form */

.home-enquiry-form {
  padding-top: 150px;
}

.home-enquiry-form form {
  margin-top: 30px;
}

.home-enquiry-form span {
  width: 100%;
}

.home-enquiry-form input {
  border: 0 !important;
  background: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 100;
  color: #fff !important;
  -webkit-transition: padding 0.3s;
  -moz-transition: padding 0.3s;
  transition: padding 0.3s;
  overflow: visible;
  height: auto;
  width: 100%;
  margin-bottom: 30px;
}

.home-enquiry-form input:focus {
  padding-bottom: 10px;
  padding-top: 10px;
  -webkit-transition: padding 0.3s;
  -moz-transition: padding 0.3s;
  transition: padding 0.3s;
}

.home-enquiry-form ::-webkit-input-placeholder {
  color: #fff;
}

.home-enquiry-form ::-moz-placeholder {
  color: #fff;
}
/* firefox 19+ */

.home-enquiry-form :-ms-input-placeholder {
  color: #fff;
}
/* ie */

.home-enquiry-form input:-moz-placeholder {
  color: #fff;
}

.home-enquiry-form input.submitbtn {
  background: #fff !important;
  border: 0 !important;
  color: #333 !important;
  font-weight: 700 !important;
  padding: 10px 30px 8px 28px !important;
  width: auto !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.home-enquiry-form input.submitbtn:hover {
  background: #1b71ce !important;
  color: #fff !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
/* Contact page form */

.contact-enquiry-form {
  padding-top: 50px;
}

.contact-enquiry-form form {
  margin-top: 60px;
}

.contact-enquiry-form span {
  width: 100%;
}

.contact-enquiry-form input {
  border: 0 !important;
  background: #f1f1f1 !important;
  box-shadow: none !important;
  border-radius: 0;
  padding: 12px;
  font-size: 1em;
  font-weight: 300;
  color: #333 !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  overflow: visible;
  height: auto;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  outline: none !important;
}

.contact-enquiry-form textarea {
  background: #f1f1f1 !important;
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: 300;
}

.contact-enquiry-form input:focus {
  border-color: #1b71ce !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-enquiry-form ::-webkit-input-placeholder {
  color: #333;
}

.contact-enquiry-form ::-moz-placeholder {
  color: #333;
}
/* firefox 19+ */

.contact-enquiry-form :-ms-input-placeholder {
  color: #333;
}
/* ie */

.contact-enquiry-form input:-moz-placeholder {
  color: #333;
}

.contact-enquiry-form input.submitbtn {
  background: #333 !important;
  border: 0 !important;
  color: #fff !important;
  font-weight: 700 !important;
  padding: 10px 30px 8px 28px !important;
  width: auto !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-enquiry-form input.submitbtn:hover {
  background: #1b71ce !important;
  color: #fff !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.contact-enquiry-form input.submitbtn:disabled {
  background: #eee !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"] {
  display: inline !important;
  width: auto !important;
}

span.wpcf7-list-item {
  margin-left: 0;
}

.wpcf7-checkbox {
  display: block;
  padding-bottom: 20px;
  clear: both;
}

.contact-enquiry-form .wpcf7-form-control-wrap.message {
  display: block;
}

div.wpcf7-response-output {
  border-width: 1px !important;
  font-size: 1.3em !important;
  margin: 0 !important;
  padding: 10px 20px !important;
  font-weight: 200 !important;
}
/* Start a project form */

.start-project-form form {
  margin-top: 50px;
}

.start-project-form span {
  width: 100%;
}

.start-project-form span.Lowball {
  width: 49% !important;
  display: inline-block !important;
}

.start-project-form span.Highball {
  width: 49% !important;
  margin-left: 2% !important;
  display: inline-block !important;
}

.start-project-form input {
  border: 0 !important;
  background: #f1f1f1 !important;
  box-shadow: none !important;
  border-radius: 0;
  padding: 12px;
  font-size: 1em;
  font-weight: 300;
  color: #333 !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  overflow: visible;
  height: auto;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  outline: none !important;
}

.start-project-form input::placeholder {
  color: #999 !important;
}

.start-project-form textarea {
  background: #f1f1f1 !important;
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: 300;
}

.start-project-form input:focus {
  border-color: #1b71ce !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.start-project-form ::-webkit-input-placeholder {
  color: #333;
}

.start-project-form ::-moz-placeholder {
  color: #333;
}
/* firefox 19+ */

.start-project-form :-ms-input-placeholder {
  color: #333;
}
/* ie */

.start-project-form input:-moz-placeholder {
  color: #333;
}

.start-project-form input.submitbtn {
  background: #333 !important;
  border: 0 !important;
  color: #fff !important;
  font-weight: 700 !important;
  padding: 10px 30px 8px 28px !important;
  width: auto !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.start-project-form input.submitbtn:hover {
  background: #1b71ce !important;
  color: #fff !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.start-project-form input.submitbtn:disabled {
  background: #eee !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.start-project-form input[type="file"] {
  border: 0 !important;
  background: 0 !important;
  box-shadow: none !important;
  border-bottom: 0 !important;
  border-radius: 0;
  padding: 0;
  font-size: 1em;
  font-weight: 100;
  color: #333 !important;
  height: auto;
  width: auto;
}

input[type="checkbox"] {
  display: inline !important;
  width: auto !important;
}

.start-project-form .wpcf7-form-control-wrap.message {
  display: block;
}

.start-project-form h4 {
  margin: 40px 0 30px;
}

.whatdoyouneedhelpwith .wpcf7-list-item,
.doyouwantit .wpcf7-list-item,
.coffee .wpcf7-list-item {
  display: inline-block;
  margin: 0 20px 0 0;
  width: auto;
}
/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
  left: 5% !important;
  background: url(../images/arrow-left.png) no-repeat !important;
  background-size: 41px 21px !important;
}

.bx-wrapper .bx-next {
  right: 5% !important;
  background: url(../images/arrow-right.png) no-repeat !important;
  background-size: 41px 21px !important;
}

.bx-wrapper .bx-prev:hover {
}

.bx-wrapper .bx-next:hover {
}

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50% !important;
  margin-top: -16px;
  outline: 0;
  width: 41px !important;
  height: 21px !important;
  text-indent: -9999px;
  z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

.bx-wrapper {
  border: 0;
  height: 100%;
  box-shadow: none;
}

.bx-viewport {
  height: 100%;
}

.post-temp {
  width: 100%;
  height: auto;
  margin-top: 12%;
}

@mixin animationDelay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -ms-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

$interval: 0.05s;
$classesToAdd: 12;
@for $i from 1 to $classesToAdd + 1 {
  ._#{$i} {
    @include animationDelay($i * $interval);
  }
}

.team-member-banner {
  display: block;
  height: 60%;
  margin-bottom: 80px;
  background-attachment: fixed !important;
}

.webdev1 {
  background: rgba(0, 0, 0, 0) url("../images/eurekaslide.jpg") no-repeat fixed
    center top / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.webdev2 {
  z-index: -100 !important;
  overflow-x: hidden;
}

.webdev3 {
  background: #fff;
  display: block;
  margin-top: -50px;
  z-index: 10 !important;
  border-top: 1px solid #eee;
}

.webdev3 .we-make,
.webdev3 .we-make div {
  height: 600px;
}

.webdev3 .we-make h3 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.webdev3 .one {
  width: 55%;
  left: 0;
  position: absolute;
}

.webdev3 .two {
  width: 45%;
  margin-left: 50%;
  left: 0;
  position: absolute;
}

.webdev3 .three {
  width: 55%;
  right: 0;
  position: absolute;
}

.webdev3 .four {
  width: 45%;
  right: 0;
  margin-right: 50%;
  position: absolute;
}

.webdev3 .five {
  width: 55%;
  left: 0;
  position: absolute;
}

.webdev3 .six {
  width: 45%;
  margin-left: 50%;
  left: 0;
  position: absolute;
}

.webdev3 .one img,
.webdev3 .two img,
.webdev3 .three img,
.webdev3 .four img,
.webdev3 .five img,
.webdev3 .six img {
  width: 100%;
}

.webdev4 {
  background: rgba(0, 0, 0, 0) url("../images/jacob-working.jpg") no-repeat
    fixed center bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0 0;
}

.client-logos {
  text-align: center;
  margin-bottom: 100px;
}

.client-logos img {
  max-width: 16.4%;
}

.client-logo {
  opacity: 0;
}

.client-logo.fadeInUp {
  opacity: 1;
}

.video1 {
  background: rgba(0, 0, 0, 0) url("../images/video-production.jpg") no-repeat
    fixed center top / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.video3 {
  background: rgba(0, 0, 0, 0) url("../images/filming.jpg") no-repeat fixed
    center bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.2%;
  /* 16:9 */
  padding-top: 0px;
  height: 0;
  margin: 50px 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.edm1 {
  background: rgba(0, 0, 0, 0) url("../images/edm-main.jpg") no-repeat fixed
    center top / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.edm4 {
  background: rgba(0, 0, 0, 0) url("../images/edm-build.jpg") no-repeat fixed
    center top / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0;
}

.edm6 .we-make,
.edm6 .we-make div {
  height: 600px;
}

.edm6 .we-make img {
  position: absolute;
}

.edm6 .we-make h3 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.edm6 .one {
  width: 55%;
  left: 0;
}

.edm6 .two {
  width: 45%;
  margin-left: 50%;
  left: 0;
}

.edm-graph {
  width: 1000px;
  left: 100%;
  margin-left: -1050px;
  position: relative;
}

.design4 {
  background: rgba(0, 0, 0, 0) url("../images/physiohealth-event.jpg") no-repeat
    fixed center bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0;
}

.design1 {
  background: rgba(0, 0, 0, 0) url("../images/inscon-branding.jpg") no-repeat
    fixed center top / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.design3 {
  height: 789px;
}

.design3 h3 {
  margin-top: 120px;
}

.magazine {
  position: relative;
  z-index: 1;
  width: 1100px;
  height: 789px;
  margin-right: -1100px;
}

.design5 .we-make,
.design5 .we-make div {
  height: 600px;
}

.design5 .we-make img {
  position: absolute;
}

.design5 .we-make h3 {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.design5 .one {
  width: 55%;
  left: 0;
}

.design5 .two {
  width: 45%;
  margin-left: 50%;
  left: 0;
}

.design5 .three {
  width: 55%;
  right: 0;
}

.design5 .four {
  width: 45%;
  right: 0;
  margin-right: 50%;
}

.strategy1 {
  background: rgba(0, 0, 0, 0) url("../images/facebook-ecom.jpg") no-repeat
    fixed center top / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.strategy3 {
  text-align: center;
  margin-top: 40px;
}

.strategy-icon {
  display: inline-block;
  width: 19%;
  text-align: center;
  margin-top: 40px;
  position: relative;
}

.fa-long-arrow-right {
  position: absolute;
  top: 20%;
  right: -15px;
  font-size: 42px;
}

.strategy4 {
  background: rgba(0, 0, 0, 0) url("../images/youtube-branding.jpg") no-repeat
    fixed center bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0 0;
}
/* ==========================================================================
   E-comm page
   ========================================================================== */

.ecom1 {
  background: rgba(0, 0, 0, 0) url("../images/e-comm-bg.jpg") no-repeat fixed
    top center / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.ecom2 {
}

.ecom6 {
  background: #1b71ce;
  display: block;
  padding-top: 100px;
  margin-bottom: 80px;
  padding-bottom: 100px;
  min-height: 600px;
}

h1 {
  font-size: 4em;
  color: #1b71ce;
  width: 100%;
  text-align: left;
  font-weight: 900;
  letter-spacing: -1px;
  margin-top: 0px;
  margin-bottom: 50px;
}

h2 {
  font-size: 3em;
  color: #1b71ce;
  width: 100%;
  text-align: left;
  font-weight: 800;
  letter-spacing: 0px;
  margin: 30px 0 30px 0;
  line-height: 35px;
}

h2.entry-title {
  font-weight: 600;
}

h2.entry-title a {
  color: #333;
}

h3 {
  font-size: 2.5em;
  color: #333;
  width: 100%;
  text-align: left;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 30px 0 50px 0;
}

h3 {
  font-size: 2.6em;
  color: #333;
  width: 100%;
  text-align: left;
  font-weight: 200;
  letter-spacing: 0px;
  margin: 30px 0 30px 0;
  line-height: 35px;
}

#atstbx {
  text-align: right;
}

.ecom6 .icon-box {
  width: 14%;
  padding: 0 1%;
  float: left;
  text-align: center;
  color: #fff;
  display: none;
}

.ecom6 .icon-box p {
  font-size: 1.5em;
  margin: 10px 0 20px;
}

.triggericons {
  clear: both;
}

.ecom6 .icon-box.bounceIn {
  display: block;
}

.ecom7 {
  overflow: hidden;
}

.bar1 {
  background: #fad595;
  height: 25px;
  display: block;
  width: 100%;
  margin-top: 275px;
}

.bar2 {
  background: #f9d089;
  height: 45px;
  display: block;
  width: 100%;
  margin-top: 255px;
}

.bar3 {
  background: #f9cc7e;
  height: 65px;
  display: block;
  width: 100%;
  margin-top: 235px;
}

.bar4 {
  background: #f8c772;
  height: 50px;
  display: block;
  width: 100%;
  margin-top: 250px;
}

.bar5 {
  background: #f8c266;
  height: 90px;
  display: block;
  width: 100%;
  margin-top: 210px;
}

.bar6 {
  background: #f7be5a;
  height: 120px;
  display: block;
  width: 100%;
  margin-top: 180px;
}

.bar7 {
  background: #f7b94f;
  height: 180px;
  display: block;
  width: 100%;
  margin-top: 120px;
}

.bar8 {
  background: #f6b543;
  height: 190px;
  display: block;
  width: 100%;
  margin-top: 110px;
}

.bar9 {
  background: #f6b037;
  height: 250px;
  display: block;
  width: 100%;
  margin-top: 50px;
}

.bar10 {
  background: #f5a720;
  height: 270px;
  display: block;
  width: 100%;
  margin-top: 30px;
}

.bar11 {
  background: #f4a214;
  height: 275px;
  display: block;
  width: 100%;
  margin-top: 25px;
}

.bar12 {
  background: #f29d0a;
  height: 300px;
  display: block;
  width: 100%;
  -vendor-animation-duration: 3s;
  -vendor-animation-delay: 2s;
  -vendor-animation-iteration-count: infinite;
}

.bars {
  display: none;
}

.bars.fadeInUpLong {
  display: block;
}

.test_images {
  background: none;
  display: block;
  height: auto;
  overflow: hidden !important;
  position: relative;
  margin-bottom: 100px;
  clear: both;
}

.ecom3 {
  background: rgba(0, 0, 0, 0) url("../images/ecom-mobile-bg.jpg") no-repeat
    fixed center bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0;
}

.ecom8 {
  background: rgba(0, 0, 0, 0) url("../images/ecom-admin.jpg") no-repeat fixed
    center bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0 0;
}

.ecom4 {
  margin: 100px 0;
  min-height: 625px;
}

.ecom4 h3 {
  margin-top: 50%;
}

.mobilevid {
  background: rgba(0, 0, 0, 0) url("../images/mobilevidbg.png") no-repeat center
    center / 310px 580px;
  display: block;
  width: 310px;
  height: 580px;
  margin-left: -310px;
  position: absolute;
  z-index: 2;
  margin-top: 80px;
  top: 0;
}

.ipad {
  position: relative;
  z-index: 1;
  width: 983px;
  height: 619px;
  margin-left: -983px;
}

.mobilevid video {
  margin-top: 84px;
  margin-left: 40px;
  position: absolute;
}

.ecom7 {
  width: 100%;
  padding-top: 100px;
  background: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
}

.layertest2 {
  position: absolute;
  top: 50%;
  left: 55%;
  margin-left: -318px;
  color: #133f71;
}

.layertest3 {
  position: absolute;
  top: 50%;
  left: 55%;
  color: #133f71;
}

.layertest4 {
  position: absolute;
  top: 50%;
  left: 5%;
  color: #133f71;
}

.pinthis {
  margin-bottom: 200px;
  height: 100%;
}

.hideme {
  display: none;
}

.mapbtn {
  cursor: pointer;
}

.map {
  width: 100%;
  height: 100%;
  position: absolute;
}

#swim {
  height: 100%;
  width: 100%;
}

.gm-style-iw * {
  display: block;
  width: 100%;
}

.gm-style-iw h4,
.gm-style-iw p {
  margin: 0;
  padding: 0;
}

.gm-style-iw a {
  color: #4272db;
}
/* ==========================================================================
   E-comm page
   ========================================================================== */

.ecom1 {
  background: rgba(0, 0, 0, 0) url("../images/custom-apps-1.jpg") no-repeat
    fixed top center / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.ecom2 {
}

.ecom6 {
  background: #1b71ce;
  display: block;
  padding-top: 100px;
  margin-bottom: 80px;
  padding-bottom: 100px;
  min-height: 600px;
}

h1 {
  font-size: 4em;
  color: #1b71ce;
  width: 100%;
  text-align: left;
  font-weight: 900;
  letter-spacing: -1px;
  margin-top: 0px;
  margin-bottom: 50px;
}

h2 {
  font-size: 3em;
  color: #1b71ce;
  width: 100%;
  text-align: left;
  font-weight: 800;
  letter-spacing: 0px;
  margin: 30px 0 30px 0;
  line-height: 35px;
}

h2.entry-title {
  font-weight: 600;
}

h2.entry-title a {
  color: #333;
}

h3 {
  font-size: 2.5em;
  color: #333;
  width: 100%;
  text-align: left;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin: 30px 0 50px 0;
}

h3 {
  font-size: 2.6em;
  color: #333;
  width: 100%;
  text-align: left;
  font-weight: 200;
  letter-spacing: 0px;
  margin: 30px 0 30px 0;
  line-height: 35px;
}

#atstbx {
  text-align: right;
}

.ecom6 .icon-box {
  width: 14%;
  padding: 0 1%;
  float: left;
  text-align: center;
  color: #fff;
  display: none;
}

.ecom6 .icon-box p {
  font-size: 1.5em;
  margin: 10px 0 20px;
}

.triggericons {
  clear: both;
}

.ecom6 .icon-box.bounceIn {
  display: block;
}

.ecom7 {
  overflow: hidden;
}

.bar1 {
  background: #fad595;
  height: 25px;
  display: block;
  width: 100%;
  margin-top: 275px;
}

.bar2 {
  background: #f9d089;
  height: 45px;
  display: block;
  width: 100%;
  margin-top: 255px;
}

.bar3 {
  background: #f9cc7e;
  height: 65px;
  display: block;
  width: 100%;
  margin-top: 235px;
}

.bar4 {
  background: #f8c772;
  height: 50px;
  display: block;
  width: 100%;
  margin-top: 250px;
}

.bar5 {
  background: #f8c266;
  height: 90px;
  display: block;
  width: 100%;
  margin-top: 210px;
}

.bar6 {
  background: #f7be5a;
  height: 120px;
  display: block;
  width: 100%;
  margin-top: 180px;
}

.bar7 {
  background: #f7b94f;
  height: 180px;
  display: block;
  width: 100%;
  margin-top: 120px;
}

.bar8 {
  background: #f6b543;
  height: 190px;
  display: block;
  width: 100%;
  margin-top: 110px;
}

.bar9 {
  background: #f6b037;
  height: 250px;
  display: block;
  width: 100%;
  margin-top: 50px;
}

.bar10 {
  background: #f5a720;
  height: 270px;
  display: block;
  width: 100%;
  margin-top: 30px;
}

.bar11 {
  background: #f4a214;
  height: 275px;
  display: block;
  width: 100%;
  margin-top: 25px;
}

.bar12 {
  background: #f29d0a;
  height: 300px;
  display: block;
  width: 100%;
  -vendor-animation-duration: 3s;
  -vendor-animation-delay: 2s;
  -vendor-animation-iteration-count: infinite;
}

.bars {
  display: none;
}

.bars.fadeInUpLong {
  display: block;
}

.test_images {
  background: none;
  display: block;
  height: auto;
  overflow: hidden !important;
  position: relative;
  margin-bottom: 100px;
  clear: both;
}

.webapp_bg1 {
  background: rgba(0, 0, 0, 0) url("../images/custom-apps-1.jpg") no-repeat
    fixed center center / cover;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.webapp_bg2 {
  background: rgba(0, 0, 0, 0) url("../images/custom-apps.jpg") no-repeat fixed
    center bottom / cover;
  display: block;
  height: 60%;
  margin: 50px 0;
}

.webapp_bg3 {
  background: rgba(0, 0, 0, 0) url("../images/custom-apps-2.jpg") no-repeat
    fixed center bottom / cover;
  display: block;
  height: 60%;
  margin: 50px 0;
}

.ecom4 {
  margin: 100px 0;
  min-height: 625px;
}

.ecom4 h3 {
  margin-top: 50%;
}

.mobilevid {
  background: rgba(0, 0, 0, 0) url("../images/mobilevidbg.png") no-repeat center
    center / 310px 580px;
  display: block;
  width: 310px;
  height: 580px;
  margin-left: -310px;
  position: absolute;
  z-index: 2;
  margin-top: 80px;
  top: 0;
}

.ipad {
  position: relative;
  z-index: 1;
  width: 983px;
  height: 619px;
  margin-left: -983px;
}

.mobilevid video {
  margin-top: 84px;
  margin-left: 40px;
  position: absolute;
}

.ecom7 {
  width: 100%;
  padding-top: 100px;
  background: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
}

.layertest2 {
  position: absolute;
  top: 50%;
  left: 55%;
  margin-left: -318px;
  color: #133f71;
}

.layertest3 {
  position: absolute;
  top: 50%;
  left: 55%;
  color: #133f71;
}

.layertest4 {
  position: absolute;
  top: 50%;
  left: 5%;
  color: #133f71;
}

.pinthis {
  margin-bottom: 200px;
  height: 100%;
}

.hideme {
  display: none;
}

.mapbtn {
  cursor: pointer;
}

.map {
  width: 100%;
  height: 100%;
  position: absolute;
}

#swim {
  height: 100%;
  width: 100%;
}

.gm-style-iw * {
  display: block;
  width: 100%;
}

.gm-style-iw h4,
.gm-style-iw p {
  margin: 0;
  padding: 0;
}

.gm-style-iw a {
  color: #4272db;
}
/* ==========================================================================
   Work with us page
   ========================================================================== */

.workwithus {
  background: rgba(0, 0, 0, 0) url("../images/work-with.jpg") no-repeat fixed
    center bottom / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.gen1 {
  margin-bottom: 100px;
}

.gen1 ul {
  font-size: 1.6em;
  font-weight: 200;
  letter-spacing: 0.3px;
  margin: 10px 0 20px;
}

.gen2 {
  padding: 120px 0;
  background: #1b71ce;
  margin-top: 100px;
}

.gen2 h3 {
  color: #fff !important;
  text-align: left !important;
}

.gen2 h3 a {
  color: #333 !important;
  text-decoration: underline;
}

.gen2 h3 a:hover {
  color: #fff !important;
  text-decoration: underline;
}

.gen3 {
  height: 100%;
  width: 100%;
  padding-top: 100px;
  background: #fff;
  position: relative;
  overflow: hidden;
}

.ourteam1 {
  background: rgba(0, 0, 0, 0) url("../images/work-with-us.jpg") no-repeat fixed
    center bottom / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.team1 {
  position: absolute;
  margin-top: -25px;
  top: 50%;
  left: 40%;
}

.team2 {
  position: absolute;
  margin-top: -25px;
  top: 50%;
  left: 10%;
}

.team3 {
  position: absolute;
  margin-top: -25px;
  top: 50%;
  left: 70%;
}

.team4 {
  position: absolute;
  margin-top: -25px;
  top: 50%;
  left: 50%;
}

#fancybox-wrap {
  padding: 0 !important;
  margin-top: 60px !important;
}

#fancybox-outer {
  box-shadow: none !important;
}

#fancybox-outer > div {
  background: none !important;
}

#fancybox-content {
  border: none !important;
  box-shadow: none !important;
}

#fancybox-overlay {
  position: fixed !important;
  cursor: url("../images/close-icon1.png"), auto !important;
}

#fancybox-content:hover,
#fancybox-wrap:hover,
#fancybox-content,
#fancybox-wrap {
  cursor: url("../images/close-icon1.png"), auto !important;
}

#fancybox-frame {
  -webkit-box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 52px 0px rgba(0, 0, 0, 0.2);
}

#fancybox-close,
#fancybox-right-ico,
#fancybox-left-ico {
  display: none !important;
}

#fancybox-left,
#fancybox-left:hover {
  cursor: url("../images/left-icon.png"), auto !important;
}

#fancybox-right,
#fancybox-right:hover {
  cursor: url("../images/right-icon1.png"), auto !important;
}

#fancybox-left,
#fancybox-right {
  width: 50% !important;
}

.large-work {
  width: 66.666666%;
  display: block;
  height: 350px;
  float: left;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.nav-person {
  background-size: cover !important;
  width: 50%;
  height: 500px;
  display: block;
  float: left;
  text-align: center;
}

.nav-person a {
  font-size: 1.6em;
  font-weight: 700;
  margin-top: 240px;
  padding: 15px;
  border: 2px solid #1b71ce;
  display: inline-block;
}

.nav-person a:hover {
  text-decoration: none;
}

.page-template-template-new-account .contact2,
.page-template-template-new-welcome .contact2 {
  padding-top: 100px;
}

.contact1 {
  background: none;
  display: block;
  height: 60%;
  margin-bottom: 80px;
  z-index: -10;
}

.contact3 {
  background: rgba(0, 0, 0, 0) url("../images/phone.jpg") no-repeat fixed center
    bottom / 100% auto;
  display: block;
  height: 60%;
  margin: 50px 0 0;
}

.small-print {
  font-size: 1.2em;
}

.integrations {
  width: 16.4%;
  display: inline-block;
  margin: 15px 0;
  opacity: 0;
}

.integrations.zoomIn {
  opacity: 1;
}

.partner1 {
  background: rgba(0, 0, 0, 0) url("../images/partner.jpg") no-repeat fixed top
    center / 100% auto;
  display: block;
  height: 60%;
  margin-bottom: 80px;
}

.partner2 {
  margin-bottom: 100px;
}

.fourohfour {
  background-size: 917px 590px;
  height: 100%;
  display: block;
  overflow: hidden !important;
  z-index: 200;
  position: relative;
}

a.xmas {
  padding: 8px 10px;
  border: 2px solid #59963e;
  margin-right: 30px;
  top: -5px;
  color: #bf2520;
  font-size: 13px;
}

a.xmas:hover {
  text-decoration: none;
  color: #59963e;
}

a.xmas-on {
  color: #fff !important;
  border-color: #fff !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.xmas-message {
  display: block;
  height: 30px;
  background: #bc241d;
  width: 100%;
  position: fixed;
  z-index: 99999;
  text-align: center;
  color: #fff !important;
  font-size: 14px;
  padding-top: 5px;
}

.xmas-message a {
  color: #fff !important;
  text-decoration: underline !important;
}
