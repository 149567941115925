


body footer{
    position:relative;
    font-weight: 500;
    color: #fff;
    width:100%;
    z-index: 0;
    -webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
    background: #333;
    padding: 20px 0;
}


body footer span.phones{
    font-size: 1.4em;
}
    


body article footer, body article footer a{
    color: #333;
    background: none;
}


body.home footer{
    position:fixed;
    bottom: 20px;
    height: 20px;
    font-weight: 500;
    color: #333;
    width:100%;
    z-index: 99999;
    -webkit-transition: color 0.3s;
	-moz-transition: color 0.3s;
	transition: color 0.3s;
    font-size: 1.4em;
    background:none;
    padding:0; 
}

body.home footer a {
    color: #333!important;
    margin-left: 10px;
}
    
  body.home footer.white, body.home footer.white a {
      color: #fff!important;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      transition: color 0.3s;
  }


.small-statement{
    font-size: 2.5em;
    color: #fff;
    letter-spacing: -0.5px;
    font-weight: 800;
    line-height: 25px;
    z-index: 99999;
    vertical-align: text-bottom;
    margin-bottom: 40px;
}



.footer-enquiry{
    background:#333;
    position: relative;
    margin-top: -1px;
    padding-top: 50px;
    z-index: 999;
    clear: both;
}


.post-type-archive-work .footer-enquiry, .post-type-archive-team-member .footer-enquiry, .single-team-member .footer-enquiry{
    margin-top: -1px;
}


.pinthis{
    margin-bottom: 200px;
    height: 100%;
}    


.footerContactBtn{
    display: block;
    text-align: center;
}


.icon-coffee:before {
	content: "";
    color: #fff;
}
     

/* Button 5 */
.contactUsBtn {
	background: #333;
	color: #fff;
	min-width: 260px;
    border-radius: 0;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
    border:2px solid #fff;
    margin: 20px 0;
    font-size: 1.6em;
    padding: 15px; 
    float: right;
}   



.contactUsBtn:active {
	background: #555;
	top: 2px;
}

.contactUsBtn span {
	display: inline-block;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.3s;
	-webkit-backface-visibility: hidden;
	-moz-transition: all 0.3s;
	-moz-backface-visibility: hidden;
	transition: all 0.3s;
	backface-visibility: hidden;
    font-weight: 700;
}

.contactUsBtn:before {
	position: absolute;
	height: 100%;
	width: 100%;
	line-height: 2.2;
	font-size: 150%;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.contactUsBtn:active:before {
	color: #fff;
}

/* Button 5a */
.btn-5a:hover span {
	-webkit-transform: translateY(300%);
	-moz-transform: translateY(300%);
	-ms-transform: translateY(300%);
	transform: translateY(300%);
}



.btn-5a:before {
	left: 0;
	top: -100%;
}

.btn-5a:hover:before {
	top: 0;
}



.footer-content{
    margin-bottom: 50px;
}


.footer-content li, .footer-content p{
    margin: 5px 0;
    list-style: none;
    font-size: 1.2em;
    font-weight: 300;
}

.footer-content p{
    font-weight: 500;
}

input.email-signup{
    border: 0!important;  
    background: 0!important;
    box-shadow: none!important;
    border-bottom:  1px solid #fff!important;
    border-radius: 0;
    padding: 0;
    font-size: 1.3em;
    font-weight: 100;
    color: #fff!important;
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
    overflow: visible;
    height: auto;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 8px;
    padding-bottom: 5px;
}
  
input.email-signup:focus{
    margin-bottom: -20px;
    padding-bottom: 10px;
    padding-top: 10px;
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}


.temp-submit{
    float: right;
    font-size: 1.5em;
    margin-top: -25px;
}


.footer-enquiry *{
    color: #fff!important;
}
