aside{
    margin-bottom: 40px;
}

.wpb_category_n_menu_accordion > ul > li > a{
    background:#333;
    color: #fff;
    
}

.wpb_category_n_menu_accordion > ul > li:not(:last-child) > a{
    border-color: #444!important;
}


.wpb_category_n_menu_accordion > ul > li li:hover > a, .wpb_category_n_menu_accordion > ul > li li.current-cat > a, .wpb_category_n_menu_accordion li.wpb-submenu-indicator-minus > a{
    border-color:#0f60c5!important;
}



.wpb_category_n_menu_accordion a:hover{
    background:#0f60c5!important;
    text-decoration: none;  
}





.widget_recent_entries ul{
    margin: 0;
    padding-left: 15px;
    font-size: 1.6em;
    font-weight: 700;
}
     

.profile-pic{
    text-align: center;
    width: 100%;
}


.avatar{
    margin: 0 auto;
    width: 100px;
    height: 100px;
    margin-top: 34px;
    margin-right: 10px;
}


aside .author{
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.4em;
    letter-spacing: 0;
    display: block;
    border-top: 1px solid #eee;
    padding-top: 12px;
    margin-bottom: 10px!important;
}
  

aside .author a, aside .the-tags a, aside .the-categories a{ 
    font-weight: 500!important;
}
 

aside .the-tags, aside .the-categories{
    font-weight: bold;
    font-size: 1.4em;
    display: block;
    border-top: 1px solid #eee;
    padding-top: 12px;
    padding-bottom: 10px;
}

aside time{
    font-size: 1.4em!important;
    display: block;
    border-top: 1px solid #eee;
    padding-top: 12px;
}


a.sharebtn{
    display: block;
    background: #eee;
    color: #0f60c5;
    font-size: 1.9em;
    text-align: center;
    padding: 8px 0 3px;
    -webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
    cursor: pointer;
}

a.sharebtn .fa{
    margin-right:10px!important;

}

a.sharebtn:hover{
    display: block;
    background: #ccc;
    color: #0f60c5;
    text-align: center;
    padding: 8px 0 3px;
    -webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}
    

aside li.menu-item > a{
    font-size: 1.4em;
    padding: 0!important;
}


.dcjq-accordion li.menu-item > a{
    color:#333!important;
    padding-left: 0px!important;
}

.dcjq-accordion{
    border: 0!important;
}


ul a.dcjq-parent{
    background: none!important;
    margin-bottom: 0!important;
    padding-left: 0!important;
    text-transform: none!important;
}


.widget_nav_menu ul, .widget_nav_menu ul{
    margin: 0;
    padding: 0;
    list-style: none;
}


.widget_nav_menu ul li a{
    color:#333!important;
    border-bottom: 1px solid #ccc!important;
    font-weight: 400!important;
    -webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.widget_nav_menu ul li a:hover{
    text-decoration: none;
    color: #0f60c5!important;
    -webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}


aside .addthis_sharing_toolbox{
    display: inline-block!important;
    padding-top: 5px;
}

aside h3{
    margin-top: 0px!important;
    font-weight: 900;
    letter-spacing: -1px;
    color: #0f60c5;
    margin: 41px 0 21px;
}

.widget_nav_menu{
    margin-bottom: 40px;
}